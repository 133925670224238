import { Controller } from "@hotwired/stimulus";
import WebViewer from '@pdftron/webviewer'

// Interface for PDFTron WebViewer
// We are utilizing stimulus values to customize the initialization of Webviewer

// UI Customization
// https://docs.apryse.com/documentation/web/guides/customizing-header/
// Stimulus Values:
// - disable-elements
//   -> comma separated list of toolbar groups to disable
//   -> ie. toolbarGroup-Shapes,toolbarGroup-Edit
//   -> options: toolbarGroup-Shapes,toolbarGroup-Edit,toolbarGroup-Insert,toolbarGroup-Forms,
//       toolbarGroup-FillAndSign,toolbarGroup-Annotate,toolbarGroup-View,ribbons
// - set-toolbar-group
//   -> toolbarGroup to set as default
//   -> ie. Annotate
// - remove-toolbar

const WHITELISTED_CUSTOMIZATIONS = [
  'prenatal_ab_2023',
];

export default class extends Controller {
  static values = {
    pdfUrl: String,
    annotationsUrl: String,
    disableElements: String,
    setToolbarGroup: String,
    removeToolbar: String,
    printButton: Boolean,
    downloadButton: Boolean,
    saveAnnotationsButton: Boolean,
    annotationDefaultsMenu: Boolean,
    signatureButton: Boolean,
    drawAnnotationsMenu: Boolean,
    autosave: Boolean,
    customizationsPath: String,
    patientId: Number,
    formBuilderMenu: Boolean,
    loadInitialAnnotations: Boolean,
    decipherUrl: String,
    splitUrl: String,
    rotateUrl: String,
    removeFirstPageUrl: String,
    reversePagesUrl: String,
    revertPdfUrl: String,
    expandButton: Boolean,
  }

  connect() {
    this.entities = [];
    if (!this.webViewerInitialized) {
      this.initializeWebViewer();
      this.webViewerInitialized = true;
    }
    window.addEventListener('message', this.fetchDecipher);
    window.addEventListener('decipher:select', this.findExtractedElement.bind(this));
  }

  fetchDecipher(event) {
    const data = event.data;
    if (data.eventType == 'fetchDecipherMessage') {
      const url = data.url;

      if (url) {
        fetch(url, {
          method: 'GET',
          headers: {
            'Accept': 'text/vnd.turbo-stream.html'
          }
        })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html));
      }
    }
  }

  findExtractedElement(event) {
    const data = event.detail;
    const iframe = this.element.querySelector('iframe');
    if (data && iframe && iframe.contentWindow) {
      const originalText = data.original_text;
      iframe.contentWindow.postMessage({ eventType: 'findText', data: { text: originalText } }, '*');
    }
  }

  initializeWebViewer() {
    window.addEventListener('message', function(event) {
      if (event.data && event.data.eventType == 'splitPagesEvent') {
        splitPages(event.data.data.url);
      }
    });
    // Note: As of Webviewer 10.6.0 WebViewer.WebComponent creates an infinite loop
    WebViewer({
      path: '/pdftron-webviewer-latest',
      initialDoc: this.pdfUrlValue,
      useDownloader: false,
      preloadWorker: 'PDF',
      enableOptimizedWorkers: false,
      stream: false,
      // Note we need this to be disabled - when this is enabled it causes
      // rendering issues with the form fields
      disableVirtualDisplayMode: true,
      css: '/pdftron_customizations/custom_css.css',
      l: "AVA Industries Ltd. (avaindustries.ca):OEM:AVA EMR, AVA Connect::B+:AMS(20241003):D4B534D204C7360A0360B13AC9A2537860614F994CD3FDE28DFF4B9A0A125027A1D6B6F5C7",
      enableAnnotations: true
    }, this.element).then((instance) => {
      // Get Access to Core Classes
      const { UI, Core } = instance;
      const { documentViewer, annotationManager } = Core;

      // For Debugging
      // window.instance = instance;
      // window.UI = UI;
      // window.Core = Core;
      // window.annotationManager = annotationManager;
      window.webviewerIframe = this.element.querySelector('iframe');

      // UI
      UI.setPrintQuality(2);

      // Start by cleaning up unused elements
      disableElements(this.disableElementsValue, UI);
      // These are never used for now
      disableElements(
        'toggleZoomOverlay,toggleNotesButton,panToolButton,selectToolButton,leftPanelButton,viewControlsButton,menuButton,divider,notesPanel,notesPanelButton,squigglyToolGroupButton,stickyToolGroupButton,toolsOverlay,strikeoutToolGroupButton,markInsertTextGroupButton,markReplaceTextGroupButton,toolsHeader,stickyToolButton,markInsertTextToolButton,markReplaceTextToolButton',
        UI
      );
      // Conditionally add in new buttons
      setToolbarGroup(this.setToolbarGroupValue, UI);
      // Allow users to have custom stamps
      if (this.downloadButtonValue) {
        addDownloadButton(UI);
      };
      if (this.annotationDefaultsMenuValue) {
        addDefaultMousePointerMenu(instance);
        addDefaultAnnotationsMenu(instance);
      };
      if (this.signatureButtonValue) {
        addSignatureButton(instance);
      };
      if (this.drawAnnotationsMenuValue) {
        addDefaultMousePointerMenu(instance);
        addDrawAnnotationsMenu(instance);
      };
      if (this.formBuilderMenuValue) {
        addFormBuilderMenu(instance);
      };
      if (this.expandButtonValue) {
        addExpandButton(UI, this.element.parentElement);
      };
      if (this.decipherUrlValue) {
        addDecipherButton(UI, this.decipherUrlValue, this, instance);
      };
      if (this.revertPdfUrlValue) {
        addRevertPdfButton(instance, this.revertPdfUrlValue, this.pdfUrlValue);
      };
      if (this.splitUrlValue) {
        addSplitPagesButton(instance, this.splitUrlValue);
      };
      if (this.reversePagesUrlValue) {
        addReversePagesButton(instance, this.reversePagesUrlValue);
      };
      if (this.removeFirstPageUrlValue) {
        addRemoveFirstPageButton(instance, this.removeFirstPageUrlValue);
      };
      if (this.rotateUrlValue) {
        addRotateButtons(instance, this.rotateUrlValue);
      };

      // Events
      documentViewer.addEventListener('documentLoaded', () => {
        UI.setFitMode(UI.FitMode.FitWidth);
        if (this.loadInitialAnnotationsValue) {
          this.loadAnnotations(annotationManager, this.annotationsUrlValue);
        }
        if (this.printButtonValue) {
          addPrintButton(instance, annotationManager, this.annotationsUrlValue, this.saveAnnotations.bind(this));
        };
        addFindTextListener(this.element, instance);
      });

      documentViewer.addEventListener('annotationsLoaded', () => {
        // Add Custom Context Menu on Inputs
        if (this.annotationDefaultsMenuValue) {
          addInputClickHooks(this.element);
        }
        // Add Autosave after initial load of Annotations
        if (this.autosaveValue) {
          initializeAutoSave(annotationManager, this.annotationsUrlValue, this.saveAnnotations.bind(this))
        }
        // Add save annotations button
        if (this.saveAnnotationsButtonValue) {
          addSaveAnnotationsButton(this.element, annotationManager, this.annotationsUrlValue, this.saveAnnotations.bind(this))
        }
        // Load Customizations
        this.loadCustomizations(instance);
      });
    })
  }

  async loadAnnotations(annotationManager, annotationsUrl) {
    if (annotationsUrl) {
      try {
        const response = await fetch(annotationsUrl, { method: 'GET' });
        if (response.ok) {
          const xfdfString = await response.text();
          await console.log(xfdfString);
          if (xfdfString) {
            await annotationManager.importAnnotations(xfdfString);
          }
        } else {
          console.error('Failed to load annotations:', response.statusText);
        }
      } catch (error) {
        console.error('Error loading annotations:', error);
      }
    }
  }

  async saveAnnotations(annotationManager, annotationsUrl) {
    if (annotationsUrl) {
      try {
        const formData = new FormData();
        const annotations = await annotationManager.exportAnnotations({ links: true, widgets: true, useDisplayAuthor: true });
        formData.append('data', annotations);
        const response = await fetch(annotationsUrl, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          saveAnnotationsToast()
        } else {
          console.error('Failed to load annotations:', response.statusText);
        }
      } catch (error) {
        console.error('Error loading annotations:', error);
      }
    }
  }

  async loadCustomizations(instance) {
    const customizations = this.customizationsPathValue;
    if (customizations && WHITELISTED_CUSTOMIZATIONS.includes(customizations)) {
      try {
        const { customizeForm } = await import(`./customizations/${customizations}.js`);
        customizeForm(this, instance);
      } catch (error) {
        console.error('Error loading customizations:', error);
      }
    } else {
      if (customizations) {
        console.error('Customization not whitelisted:', customizations);
      }
    }
  }

}

function addPrintButton(instance, annotationManager = null, annotationsUrl = null, saveAnnotationsFunction = null) {
  const { UI, Core } = instance;
  const { documentViewer } = Core;
  // Get the print button element
  const printButton = {
    type: 'actionButton',
    dataElement: 'printButton',
    title: 'Print',
    img: 'icon-header-print-line',
    onClick: () => {
      if (annotationManager && annotationsUrl && saveAnnotationsFunction) {
        saveAnnotationsFunction(annotationManager, annotationsUrl)
      }
      instance.UI.useEmbeddedPrint(true);
      UI.print({
        includeAnnotations: true
      });
    },
  };

  // Add the print button to the main toolbar
  UI.setHeaderItems(header => {
    header.getHeader('default').push(printButton);
  });
}

function addRotateButtons(instance, url) {
  const { UI } = instance;

  const rotateClockwise = {
    type: 'actionButton',
    dataElement: 'rotateClockwiseButton',
    title: 'Rotate 90 Degrees (Clockwise)',
    img: 'icon-header-page-manipulation-page-rotation-clockwise-line',
    onClick: () => {
      rotatePDF(instance, url, 90);
    },
  };

  const rotateCounterclockwise = {
    type: 'actionButton',
    dataElement: 'rotateCounterclockwiseButton',
    title: 'Rotate 90 Degrees (Counter clockwise)',
    img: 'icon-header-page-manipulation-page-rotation-counterclockwise-line',
    onClick: () => {
      rotatePDF(instance, url, 270);
    },
  };

  const rotateUpsideDown = {
    type: 'actionButton',
    dataElement: 'rotateUpsideDownButton',
    title: 'Rotate 180 Degrees',
    img: 'icon-header-page-manipulation-page-rotation-clockwise-line',
    onClick: () => {
      rotatePDF(instance, url, 180);
    },
  };

  UI.setHeaderItems(header => {
    // header.getHeader('default').unshift({
    //    type: 'divider',
    // });
    // For now will remove the 180 as it takes up space and no good icon for it
    header.getHeader('default').unshift(rotateClockwise);
    // header.getHeader('default').unshift(rotateUpsideDown);
    header.getHeader('default').unshift(rotateCounterclockwise);
  });
}

function rotatePDF(instance, url, degrees) {
  const { documentViewer } = instance.Core;
  const doc = documentViewer.getDocument();
  const pageCount = documentViewer.getPageCount();
  const pagesArray = Array.from({ length: pageCount }, (_, i) => i + 1);  // Create an array of all pages

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(`${url}?degrees=${degrees}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  })
  .then(response => {
    if (response.ok) {
      if (degrees == 90) {
        doc.rotatePages(pagesArray, instance.Core.PageRotation.E_90);
      } else if (degrees == 180) {
        doc.rotatePages(pagesArray, instance.Core.PageRotation.E_180);
      } else if (degrees == 270) {
        doc.rotatePages(pagesArray, instance.Core.PageRotation.E_270);
      }
    } else {
      throw new Error('Server failed to rotate PDF');
    }
  })
  .catch(error => {
    console.error('Error rotating PDF on server:', error);
  });
}

function addRemoveFirstPageButton(instance, url) {
  const { UI } = instance;

  const removeFirstPageButton = {
    type: 'actionButton',
    dataElement: 'removeFirstPageButton',
    title: 'Remove First Page',
    img: 'icon-header-page-manipulation-page-layout-cover-line',
    onClick: () => {
      removeFirstPage(instance, url, 90);
    },
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').unshift(removeFirstPageButton);
  });
}

function removeFirstPage(instance, url) {
  const { documentViewer } = instance.Core;
  const doc = documentViewer.getDocument();

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  })
  .then(response => {
    if (response.ok) {
      doc.removePages([1]);
    } else {
      throw new Error('Server failed to rotate PDF');
    }
  })
  .catch(error => {
    console.error('Error rotating PDF on server:', error);
  });
}

function addRevertPdfButton(instance, revertUrl, displayUrl) {
  const { UI, documentViewer } = instance;

  const revertPdfButton = {
    type: 'actionButton',
    dataElement: 'revertPdfButton',
    title: 'Revert to Original',
    img: 'icon-action-undo',
    onClick: () => {
      revertPdf(instance, revertUrl, displayUrl);
    },
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').unshift(revertPdfButton);
  });
}

function revertPdf(instance, revertUrl, displayUrl) {
  const { UI, documentViewer } = instance.Core;

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(`${revertUrl}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  })
  .then(response => {
    if (response.ok) {
      documentViewer.loadDocument(displayUrl);
    } else {
      throw new Error('Server failed to rotate PDF');
    }
  })
  .catch(error => {
    console.error('Error rotating PDF on server:', error);
  });
}

function addReversePagesButton(instance, url) {
  const { UI } = instance;

  const reversePagesButton = {
    type: 'actionButton',
    dataElement: 'reversePagesButton',
    title: 'Reverse Pages',
    img: 'icon-header-page-manipulation-insert-above',
    onClick: () => {
      reversePageOrder(instance, url);
    },
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').unshift(reversePagesButton);
  });
}

function reversePageOrder(instance, url) {
  const { documentViewer } = instance.Core;
  const doc = documentViewer.getDocument();
  const pageCount = documentViewer.getPageCount();
  const newOrder = [];

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  })
  .then(response => {
    if (response.ok) {
      // Create an array with pages in reverse order
      for (let i = pageCount; i > 1; i--) {
        newOrder.push(i);
      }

      // Apply the new page order
      doc.movePages(newOrder, 1);
    } else {
      throw new Error('Server failed to rotate PDF');
    }
  })
  .catch(error => {
    console.error('Error rotating PDF on server:', error);
  });
}

function addExpandButton(UI, el) {
  const expandButton = {
    type: 'actionButton',
    dataElement: 'expandButton',
    title: 'Expand PDF',
    img: 'icon-header-zoom-fit-to-page',
    onClick: () => {
      toggleExpandButton(el);
    },
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').push(expandButton);
  });
}

function toggleExpandButton(el) {
  el.classList.toggle('focus-mode-active');
};

function addSplitPagesButton(instance, url) {
  const { UI } = instance;

  const reversePagesButton = {
    type: 'actionButton',
    dataElement: 'splitPagesButton',
    title: 'Split PDF',
    img: 'icon-cut',
    onClick: () => {
      parent.postMessage({ eventType: 'splitPagesEvent', data: { url: url } }, '*');
    },
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').unshift(reversePagesButton);
  });
}

function splitPages(url) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  // Note: using fetch caused us to need to skip csrf protection for an unknown reason - staying with jQ for now
  $.ajax({
    url: url,
    type: 'GET',
    headers: {
      'X-CSRF-Token': csrfToken
    },
    dataType: 'script'
  })
}

function addDownloadButton(UI) {
  // Get the print button element
  const downloadButton = {
    type: 'actionButton',
    dataElement: 'downloadButton',
    title: 'Download',
    img: 'icon-download',
    onClick: () => {
      UI.downloadPdf({
        includeAnnotations: true,
        flatten: true
      });
    },
  };

  // Add the print button to the main toolbar
  UI.setHeaderItems(header => {
    header.getHeader('default').push(downloadButton);
  });
}

function addDecipherButton(UI, url, controller, instance) {
  const decipherButton = {
    type: 'actionButton',
    dataElement: 'decipherButton',
    title: 'AI: Decipher',
    img: 'icon-page-manipulation-extract',
    onClick: () => {
      parent.postMessage({ eventType: 'fetchDecipherMessage', url: url }, '*');
      // if (controller.entities.length === 0) {
      //   fetch(url).then(response => response.json()).then(entities => {
      //     controller.entities = entities;
      //     displayDecipherEntities(instance, controller.entities);
      //   });
      // } else {
      //   displayDecipherEntities(instance, controller.entities);
      // }
    }
  };

  UI.setHeaderItems(header => {
    header.getHeader('default').push(decipherButton);
  });
}

function addFindTextListener(element, instance) {
  const iframe = element.querySelector('iframe');
  iframe.contentWindow.addEventListener('message', function(event) {
    if (event.data && event.data.eventType == 'findText' && event.data.data.text) {
      findText(instance, event.data.data.text);
    }
  });
}

function findText(instance, searchText) {
  const { documentViewer, Search } = instance.Core;

  const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
  const searchOptions = {
    fullSearch: true,
    onResult: result => {
      if (result.resultCode === Search.ResultCode.FOUND) {
        documentViewer.displaySearchResult(result);
      }
    }
  };

  documentViewer.textSearchInit(searchText, mode, searchOptions);
}

function addSaveAnnotationsButton(element, annotationManager, annotationsUrl, saveAnnotationsFunction) {
  var newDiv = document.createElement('div');
  newDiv.className = 'button wide-button grey-border';
  newDiv.id = 'save-pdf-button-skeleton';
  newDiv.innerHTML = '<i class="fa fa-floppy-o disabled"></i> Save Form Data';

  // Add click event listener
  newDiv.addEventListener('click', async () => {
    // Trigger the saveAnnotationsFunction
    try {
      await saveAnnotationsFunction(annotationManager, annotationsUrl);
    } catch (error) {
      console.error('Error saving annotations:', error);
    }
  });

  element.parentNode.insertBefore(newDiv, element);
}

function openDialog(instance, textContent) {
  const { UI } = instance;
  const dialog = new UI.Popup();
  
  dialog.dataElement = 'searchResultDialog';
  dialog.title = 'Text Found';
  dialog.content = `<div style='padding: 10px;'>Found Text: ${textContent}</div>`; // Customize content as needed
  dialog.show();
}

function setToolbarGroup(element, UI) {
  if (element) {
    try {
      UI.setToolbarGroup(element);
    } catch (error) {
      console.error('Error setting toolbarGroup:', error);
    }
  } else {
    UI.setToolbarGroup('toolbarGroup-View');
  }
}

function disableElements(elements, UI) {
  if (elements) {
    try {
      elements.split(',').forEach((element) => {
        console.log("Disabling: ", element)
        UI.disableElements([element]);
      })
    } catch (error) {
      console.error('Error disabling element:', error);
    }
  }
}

function enableElement(element, UI) {
  console.log("Enabling: ", element);
  UI.enableElements(element);
}

function saveAnnotationsToast() {
  $('#toast-success').html('Saved annotations!').fadeIn(300).delay(1500).fadeOut(300)
}

function addDefaultMousePointerMenu(instance) {
  const { UI } = instance;
  const { documentViewer } = instance.Core;

  UI.setHeaderItems(header => {
    // Find the index of the search button
    let buttonIndex = header.getItems().findIndex(item => item.dataElement === 'searchButton');

    // Fallback index if the search button is not found
    const fallbackIndex = 5;

    // Use the fallback index if search button is not found
    if (buttonIndex === -1) {
      buttonIndex = Math.min(fallbackIndex, header.length);  // Ensure index is within bounds
    }

    // Define your custom button
    const defaultSelectButton = {
      type: 'actionButton',
      img: 'ic_select_black_24px',
      onClick: () => {
        const textSelectTool = documentViewer.getTool('TextSelect');
        if (textSelectTool) {
          documentViewer.setToolMode(textSelectTool);
        } else {
          console.log('no text select tool found');
        }
      },
      title: 'Default Select Tool'
    };

    // Insert the custom button at the determined index
    const items = header.getItems()
    items.splice(buttonIndex, 0, defaultSelectButton);

    // Insert the divider right after the custom button
    header.update(items);
  });
}

function addDefaultAnnotationsMenu(instance) {
  const { UI } = instance;

  UI.setHeaderItems(header => {
    // Find the index of the search button
    let buttonIndex = header.getItems().findIndex(item => item.dataElement === 'searchButton');

    // Fallback index if the search button is not found
    const fallbackIndex = 5;

    // Use the fallback index if search button is not found
    if (buttonIndex === -1) {
      buttonIndex = Math.min(fallbackIndex, header.length);  // Ensure index is within bounds
    }

    // Define your custom button
    const customButton = {
      type: 'actionButton',
      img: 'icon-form-field-signature',
      onClick: () => addSignaturePlaceholder(instance),
      title: 'Add Signature Field'
    };

    // Define a divider
    const divider = {
      type: 'divider'
    };

    // Insert the custom button at the determined index
    const items = header.getItems()
    items.splice(buttonIndex, 0, customButton, divider);

    // Insert the divider right after the custom button
    header.update(items);
  });
}

function addSignatureButton(instance) {
  const { UI } = instance;

  UI.setHeaderItems(header => {
    // Find the index of the search button
    let buttonIndex = header.getItems().findIndex(item => item.dataElement === 'searchButton');

    // Fallback index if the search button is not found
    const fallbackIndex = 5;

    // Use the fallback index if search button is not found
    if (buttonIndex === -1) {
      buttonIndex = Math.min(fallbackIndex, header.length);  // Ensure index is within bounds
    }

    // Define your custom button
    const customButton = {
      type: 'actionButton',
      img: 'icon-form-field-signature',
      onClick: () => addSignature(instance),
      title: 'Add Signature'
    };

    // Define a divider
    const divider = {
      type: 'divider'
    };

    // Insert the custom button at the determined index
    const items = header.getItems()
    items.splice(buttonIndex, 0, customButton, divider);

    // Insert the divider right after the custom button
    header.update(items);
  });
}

function addDrawAnnotationsMenu(instance) {
  const { UI } = instance;

  UI.setHeaderItems(header => {
    // Find the index of the search button
    let buttonIndex = header.getItems().findIndex(item => item.dataElement === 'searchButton');

    // Fallback index if the search button is not found
    const fallbackIndex = 5;

    // Use the fallback index if search button is not found
    if (buttonIndex === -1) {
      buttonIndex = Math.min(fallbackIndex, header.length);  // Ensure index is within bounds
    }

    const textBoxButton = {
      type: 'actionButton',
      img: 'icon-tool-text-free-text',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.FREETEXT);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(0, 0, 0),
          TextColor: new Annotations.Color(0, 0, 0),
          FontSize: '14pt',
        }));
      },
      title: 'Add Text'
    };

    const rectangleButton = {
      type: 'actionButton',
      img: 'icon-tool-shape-rectangle',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.RECTANGLE);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(0, 0, 0),
          StrokeThickness: 4,
        }));
      },
      title: 'Rectangle Box'
    };

    const freeHandButton = {
      type: 'actionButton',
      img: 'icon-tool-pen-line',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.FREEHAND);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(0, 0, 0),
          StrokeThickness: 4,
        }));
      },
      title: 'Freehand Drawing'
    };

    const freeHandHighlightButton = {
      type: 'actionButton',
      img: 'icon-tool-pen-highlight',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.FREEHAND_HIGHLIGHT);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(245, 245, 5),
          StrokeThickness: 10,
        }));
      },
      title: 'Freehand Highlighting'
    };

    const textUnderlineButton = {
      type: 'actionButton',
      img: 'icon-menu-text-underline',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.UNDERLINE);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(255, 30, 30),
          StrokeThickness: 4,
        }));
      },
      title: 'Underline Text'
    };

    const textHighlightButton = {
      type: 'actionButton',
      img: 'icon-tool-highlight',
      onClick: () => {
        const { Tools, documentViewer, Annotations } = instance.Core;

        // Set the current tool to the FreeText tool
        const tool = documentViewer.getTool(Tools.ToolNames.HIGHLIGHT);
        documentViewer.setToolMode(tool);
    
        // Optionally, you can also set tool properties if needed
        tool.setStyles(currentStyle => ({
          StrokeColor: new Annotations.Color(245, 245, 5),
        }));
      },
      title: 'Highlight Text'
    };

    const stampButton = {
      type: 'actionButton',
      title: 'Stamp',
      img: 'icon-tool-image-line',
      onClick: () => {
        const { Tools, documentViewer } = instance.Core;

        const tool = documentViewer.getTool(Tools.ToolNames.STAMP);
        documentViewer.setToolMode(tool);
      }
    };

    // Define a divider
    const divider = {
      type: 'divider'
    };

    // Insert the custom button at the determined index
    const items = header.getItems()
    items.splice(
      buttonIndex,
      0,
      textBoxButton, rectangleButton, freeHandButton, freeHandHighlightButton, textUnderlineButton, textHighlightButton, stampButton, divider
    );

    // Insert the divider right after the custom button
    header.update(items);
  });
}

function addFormBuilderMenu(instance) {
  enableElement('toolsHeader', instance.UI);
  disableElements('toolbarGroup-Shapes,toolbarGroup-Annotate,toolbarGroup-Insert,toolbarGroup-Edit,toolbarGroup-FillAndSign', instance.UI);
  enableElement('ribbons', instance.UI);
}

function addSignature(instance) {
  addStamp(instance, 'Signature', '/account_management/signatures')
}

function addSignaturePlaceholder(instance) {
  addStamp(instance, 'SignaturePlaceholder', '/images/mock_signature.png')
}

function addStamp(instance, subject, url) {
  const { Core } = instance;
  const { Annotations, annotationManager, documentViewer } = Core;

  const currentPage = documentViewer.getCurrentPage();
  const pageRotation = documentViewer.getCompleteRotation(currentPage) * 90;

  // Code for getting current position
  const webviewerDocument = documentViewer.getDocument();
  const pageInfo = webviewerDocument.getPageInfo(currentPage);
  const zoom = documentViewer.getZoomLevel();
  const centerX = (pageInfo.width / 2);
  const centerY = (pageInfo.height / 2);

  const placeholderAnnot = new Annotations.StampAnnotation({
    PageNumber: currentPage,
    X: centerX,
    Y: centerY,
    Width: 160,
    Height: 40,
    Icon: 'FINAL',
    MaintainAspectRatio: true,
    Rotation: pageRotation,
  });
  placeholderAnnot.Subject = subject;
  
  // Load your image as a data URL and set it as ImageData
  const imageUrl = url;  // URL of your JPG image
  fetch(imageUrl)
    .then(response => response.blob())
    .then(blob => {
      const reader = new FileReader();
      reader.onload = () => {
        placeholderAnnot.setImageData(reader.result);
        annotationManager.addAnnotation(placeholderAnnot);
        annotationManager.redrawAnnotation(placeholderAnnot);
        annotationManager.deselectAllAnnotations();
        annotationManager.selectAnnotation(placeholderAnnot);
      };
      reader.readAsDataURL(blob);
    });
}

function addInputClickHooks(element, annotationManager) {
  // Capture the iframe element
  const iframe = element.querySelector('iframe');
  if (!iframe) return;

  const iframeBody = iframe.contentWindow.document.body;

  // Remove existing click event listener if any
  iframeBody.removeEventListener('click', handleBodyClick);
  iframeBody.addEventListener('click', handleBodyClick);

  // Add context menu event listener
  iframeBody.removeEventListener('contextmenu', handleContextMenu);
  iframeBody.addEventListener('contextmenu', handleContextMenu);
}

function handleBodyClick(event) {
  // Logic to hide custom context menu
  hideCustomContextMenu();
}

function handleContextMenu(event) {
  const target = event.target;

  // Check if the target of the click is an input element
  if (target.tagName.toLowerCase() === 'input' || target.tagName.toLowerCase() === 'textarea') {
    event.preventDefault();
    // Logic to add data on click
    addDataOnClickLi(target);

    // Show custom context menu
    showCustomContextMenu(event);
  }
}

function addDataOnClickLi(newTarget) {
  document.querySelectorAll('.custom-context-menu-options li').forEach(li => {
    // Ensure each li element has a unique handler
    if (!li.clickHandler || li.clickHandler.target !== newTarget) {
      if (li.clickHandler) {
        li.removeEventListener('click', li.clickHandler);
      }
      li.clickHandler = function() {
        const dataValue = this.getAttribute('data-value');
        updateFieldWithValue(newTarget, dataValue);
      };
      li.addEventListener('click', li.clickHandler);
      li.clickHandler.target = newTarget; // Associate the target with the handler
    }
  });
}

function updateFieldWithValue(target, value) {
  if (!target || !value) return;
  // This worked for some fields but was not ideal
  // Keeping for now incase we really need to move back - however triggering the input event seems to be most effective
  // const closestDiv = target.closest('div');
  // const fieldName = closestDiv ? closestDiv.id : null;
  // For some reason just changing the value of the field does not work so we have to use the field manager
  // if (!fieldName) return;
  // instance.Core.annotationManager.getFieldManager().forEachField(field => {
  //   if (field.value) {
  //     console.log("Field Name: ", field.name, "Field Value: ", field.value, "Target Fieldname: ", fieldName);
  //   }
  //   if (field.name === fieldName) {
  //     console.log("Found Field: ", field);
  //     field.setValue(value);
  //   }
  // });
  target.value = value;
  target.dispatchEvent(new Event('input'));
  target.dispatchEvent(new Event('blur'));
  document.querySelector('.custom-context-menu').style.display = 'none';
}

function showCustomContextMenu(event) {
  // Get the position of the iframe
  const iframe = document.querySelector('iframe');
  const iframePosition = iframe.getBoundingClientRect();

  // Find the custom context menu element
  const customContextMenu = document.querySelector('.custom-context-menu');

  // Calculate the position and display the menu
  customContextMenu.style.left = (event.clientX + iframePosition.left) + 'px';
  customContextMenu.style.top = (event.clientY + iframePosition.top) + 'px';
  customContextMenu.style.display = 'block';
}

function hideCustomContextMenu() {
  const customContextMenu = document.querySelector('.custom-context-menu');
  customContextMenu.style.display = 'none';
}

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

function initializeAutoSave(annotationManager, annotationsUrl, saveAnnotationsFunction) {
  // Auto-save function
  const autoSaveAnnotations = debounce(function() {
    // Call your save annotations function here
    saveAnnotationsFunction(annotationManager, annotationsUrl);
  }, 3000); // 3-second delay

  // Listen for annotation changes
  annotationManager.addEventListener('annotationChanged', (annotations, action, { imported }) => {
    if (imported) return;
    if (action === 'add' || action === 'modify' || action === 'delete') {
      autoSaveAnnotations();
    }
  });

  // Listen for form changes
  annotationManager.addEventListener('fieldChanged', (annotations, action) => {
    autoSaveAnnotations();
  });
}
